<style>
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}
</style>
<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Crear Funciones</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row">
              <base-input
                class="col-md-3"
                type="text"
                label="Nombre"
                placeholder="Nombre"
                v-model="form.data.name"
              />
              <base-input
                class="col-md-3"
                type="text"
                label="Función"
                placeholder="Función"
                v-model="form.data.function"
              />
              <base-input
                class="col-md-3"
                type="text"
                label="Descripción"
                placeholder="Descripción"
                v-model="form.data.description"
              />
              <base-input
                class="col-md-1"
                type="number"
                label="Cantidad"
                placeholder="Cantidad"
                v-model="form.data.amount"
              />
              <base-input class="col-md-2" label="Formato">
                <select v-model="form.data.formats" class="form-control">
                  <option value="">Ninguno</option>
                  <option value="a4">A4</option>
                  <option value="a3">A3</option>
                </select>
              </base-input>
            </div>
            <div class="form-row">
              <base-input class="col-md-2" label="Fuente">
                <select v-model="form.data.fonts" class="form-control">
                  <option value="">Ninguno</option>
                  <option value="arial">Arial</option>
                </select>
              </base-input>
              <base-input class="col-md-2" label="Ambiente">
                <select v-model="form.data.enviroment" class="form-control">
                  <option value="editor">Editor</option>
                  <option value="web">Web</option>
                  <option value="mobile">Mobile</option>
                </select>
              </base-input>
              <div class="form-check form-check-inline col-md-1 mb-2">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="form.data.enabled"
                  />
                  ¿Activo?
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="form-check form-check-inline col-md-1 mb-2">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="form.data.unlimited"
                  />
                  Ilimitado
                  <span class="form-check-sign"></span>
                </label>
              </div>
              <div class="from-group col-md-4 mt-3">
                <base-button type="primary" native-type="Submit"
                  >Guardar</base-button
                >
              </div>
            </div>
          </form>
        </card>
      </div>
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Lista de Funciones</h4>

          <table
            id="myTable"
            class="table table-responsive"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Función</th>
                <th>Descripción</th>
                <th>Activo</th>
                <th>Cantidad</th>
                <th>Formato</th>
                <th>Fuente</th>
                <th>Ilimitado</th>
                <th>Ambiente</th>
                <th>Activo</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in functionalities" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.function }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.enabled }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.formats }}</td>
                <td>{{ item.fonts }}</td>
                <td>{{ item.unlimited }}</td>
                <td>{{ item.enviroment }}</td>
                <td>{{ item.isActive }}</td>
                <td>
                  <base-button
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                    @click="modals.edit = true"
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                    @click="deletePlan(item.id)"
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </td>
              </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";

export default {
  components: { BaseTable, DataTable },

  data() {
    return {
      table: null,
      functionalities: [],
      users: [],
      form: {
        data: {
          name: "",
          description: "",
          enabled: false,
          amount: 0,
          formats: "",
          fonts: "",
          unlimited: false,
          enviroment: "editor",
        },
      },
    };
  },
  watch: {
    functionalities() {
      this.table.destroy();
      // data used in the table
      this.$nextTick(() => {
        // wait until data fully updated before re-render new DOM
        this.table = new DataTable("#myTable", {
          //  scrollX: true,
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
  },
  async mounted() {
    this.table = new DataTable("#myTable", {
      //scrollX: true,
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getPlans();
    await this.getFunctionalities();
    await this.getUsers();
  },
  methods: {
    async getPlans() {
      await this.$store.dispatch("plans/list");
      this.plans = await this.$store.getters["plans/list"];
    },
    async getFunctionalities() {
      await this.$store.dispatch("functionalities/list");
      this.functionalities = await this.$store.getters["functionalities/list"];
    },
    async getUsers() {
      await this.$store.dispatch("users/list");
      this.users = await this.$store.getters["users/list"];
    },
    async handleSubmit() {
      this.form.data.formats = "['A4']";
      try {
        await this.$store.dispatch("functionalities/add", this.form.data);
        this.$notify({
          type: "success",
          message: "Funcionalidad fue creada.",
        });
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
  },
};
</script>
