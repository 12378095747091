<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Crear Plan</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row">
              <base-input
                class="col-md-3"
                type="text"
                label="Nombre"
                placeholder="Nombre"
                v-model="form.data.name"
              />
              <base-input
                class="col-md-3"
                type="text"
                label="Descripción"
                placeholder="Descripción"
                v-model="form.data.description"
              />
              <base-input
                class="col-md-3"
                type="number"
                step="0.01"
                label="Costo"
                placeholder="Costo"
                v-model="form.data.cost"
              />
              <div class="form-check form-check-inline col-md-2 mb-2">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="form.data.is_default"
                  />
                  ¿Por defecto?
                  <span class="form-check-sign"></span>
                </label>
              </div>
            </div>
            <div class="form-row">
              <base-input
                class="col-md-2"
                type="number"
                label="Cantidad"
                placeholder="Cantidad"
                v-model="form.data.quantity"
              />
              <base-input class="col-md-2" label="Por tiempo">
                <select v-model="form.data.period_time" class="form-control">
                  <option value="d">Día</option>
                  <option value="m">Mes</option>
                  <option value="y">Año</option>
                  <option value="i">Indefinido</option>
                </select>
              </base-input>
              <base-input
                class="col-md-2"
                type="number"
                label="Cantidad Plantillas"
                placeholder="Cantidad Plantillas"
                v-model="form.data.template_quantity"
              />
              <base-input class="col-md-2" label="Estado">
                <select v-model="form.data.status" class="form-control">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="y">Año</option>
                  <option value="i">Indefinido</option>
                </select>
              </base-input>
              <div class="form-check form-check-inline col-md-3 mb-2">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="form.data.is_watermark"
                  />
                  ¿Tiene marca de agua?
                  <span class="form-check-sign"></span>
                </label>
              </div>
            </div>
            <base-button type="primary" native-type="Submit"
              >Guardar</base-button
            >
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      roles: [],
      form: {
        data: {
          name: "",
          description: "",
          period_time: "m",
          quantity: 0,
          status: "active",
          is_watermark: false,
          template_quantity: 0,
          cost: 0,
          is_default: false,
        },
      },
    };
  },

  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch("plans/add", this.form.data);
        this.$notify({
          type: "success",
          message: "El plan fue creado.",
        });
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
  },
};
</script>
