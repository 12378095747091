<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Crear Cupones</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row" style="column-gap: 20px">
              <div class="form-row ml-2 col-md-3">
                <div class="row" style="flex-wrap: nowrap; width: 100%">
                  <base-input
                    class="col-sm-10"
                    type="text"
                    label="Código"
                    placeholder="Código"
                    v-model="form.data.code"
                  />
                  <button
                    type="button"
                    class="btn btn-warning btn-fab btn-icon btn-round mt-4 col-md-1"
                    v-on:click="codigoAleatorio"
                  >
                    <i class="tim-icons icon-refresh-02"></i>
                  </button>
                </div>
              </div>

              <base-input
                class="col-md-3"
                type="date"
                label="Fecha limite"
                placeholder="Fecha limite"
                v-model="form.data.limit_date"
              />
              <base-input class="col-md-3" label="Plan">
                <select class="form-control" v-model="form.data.plan_id">
                  <option v-for="item in plans" :value="item.id" :key="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </base-input>
              <div class="form-check form-check-inline col-md-2 mb-2">
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="form.data.is_unique_use"
                  />
                  ¿Es de uso único?
                  <span class="form-check-sign"></span>
                </label>
              </div>
            </div>
            <base-button
              type="primary"
              native-type="Submit"
              class="float-right"
            >
              Guardar
            </base-button>
          </form>
        </card>
      </div>

      <div class="col-12">
        <button
          class="btn btn-default animation-on-hover"
          type="button"
          :class="visible ? '' : 'collapsed'"
          :aria-expanded="visible ? 'true' : 'false'"
          aria-controls="collapse-4"
          @click="visible = !visible"
        >
          Crear cupones masivos
        </button>

        <b-collapse id="collapse-4" v-model="visible" class="mt-2">
          <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">Crear Cupones Masivos</h4>
            <form @submit.prevent="handleSubmitMasivo()">
              <div class="form-row">
                <base-input
                  class="col-md-3"
                  type="number"
                  label="Cantidad de cupones"
                  placeholder="Cantidad"
                  v-model="form.masivo.cantidad"
                />
                <base-input
                  class="col-md-3"
                  type="date"
                  label="Fecha limite"
                  placeholder="Fecha limite"
                  v-model="form.masivo.limit_date"
                />
                <base-input class="col-md-3" label="Plan">
                  <select class="form-control" v-model="form.masivo.plan_id">
                    <option
                      v-for="item in plans"
                      :value="item.id"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </base-input>
                <div class="form-check form-check-inline col-md-2 mb-2">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="form.masivo.is_unique_use"
                    />
                    ¿Es de uso único?
                    <span class="form-check-sign"></span>
                  </label>
                </div>
              </div>
              <base-button
                type="primary"
                native-type="Submit"
                class="float-right"
              >
                Guardar
              </base-button>
            </form>
          </card>
        </b-collapse>
      </div>

      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Lista de Cupones</h4>

          <div>
            <table
              id="myTable"
              class="table table-responsive"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th style="width: 30%">Código</th>
                  <th>¿Es de único uso?</th>
                  <th style="width: 20%">Fecha limite</th>
                  <th style="width: 30%">Plan</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in coupons" :key="item.id">
                  <td>{{ item.code }}</td>
                  <td>{{ item.isUniqueUse }}</td>
                  <td>{{ item.limitDate }}</td>
                  <td>{{ item.plan }}</td>
                  <td>{{ item.status }}</td>
                  <td>
                    <!--<base-button
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                    @click="modals.edit = true"
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                    @click="deletePlan(item.id)"
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import { BCollapse } from "bootstrap-vue";

export default {
  components: { BaseTable, DataTable, BCollapse },

  data() {
    return {
      table: null,
      coupons: [],
      plans: [],
      visible: false,
      form: {
        data: {
          code: "",
          is_unique_use: false,
          limit_date: "2023-10-01",
          plan_id: 1,
        },
        masivo: {
          cantidad: 1,
          is_unique_use: false,
          limit_date: "2023-10-01",
          plan_id: 1,
        },
      },
    };
  },
  watch: {
    coupons() {
      this.table.destroy();
      // data used in the table
      this.$nextTick(() => {
        // wait until data fully updated before re-render new DOM
        this.table = new DataTable("#myTable", {
          //  scrollX: true,
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
  },
  async mounted() {
    this.table = new DataTable("#myTable", {
      //scrollX: true,
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getPlans();
    await this.getCoupons();
  },
  methods: {
    async codigoAleatorio() {
      //const idx = Math.floor(Math.random() * 100000000);
      //this.form.data.code = idx.toString();

      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 8) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      this.form.data.code = result;
    },
    async getPlans() {
      await this.$store.dispatch("plans/list");
      this.plans = await this.$store.getters["plans/list"];
    },
    async getCoupons() {
      await this.$store.dispatch("coupons/list");
      this.coupons = await this.$store.getters["coupons/list"];
    },
    async handleSubmitMasivo() {
      try {
        await this.$store.dispatch("coupons/massive", this.form.masivo);
        this.$notify({
          type: "success",
          message: "Los cupones fueron creados",
        });

        await this.getCoupons();
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
    async handleSubmit() {
      try {
        await this.$store.dispatch("coupons/add", this.form.data);
        this.$notify({
          type: "success",
          message: "El cupón fue creado.",
        });

        await this.getCoupons();
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
  },
};
</script>
