<style>
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}
</style>
<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Listar láminas</h4>
          <div class="text-right mb-3">
            <a href="/laminas/lamina-create" class="btn btn-primary"
              >Añadir Láminas</a
            >
          </div>
          <div>
            <table
              id="myTable"
              class="table table-responsive"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nombre</th>
                  <th>Más vista</th>
                  <th>Recomendada</th>
                  <th>Horizontal</th>
                  <th>Estado</th>
                  <th>N° vistas</th>
                  <th>Tira</th>
                  <th>Fecha creación</th>
                  <th>Fecha Actualización</th>
                  <th style="width: 13%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sheets" :key="item.id">
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.isMostSeen }}</td>
                  <td>{{ item.isRecommended }}</td>
                  <td>{{ item.isHorizontal }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.numberOfViews }}</td>
                  <td>
                    <button
                      v-on:click="showImage(item.tira)"
                      class="btn btn-info btn-sm"
                    >
                      Ver
                    </button>
                  </td>
                  <td>{{ item.createdAt }}</td>
                  <td>{{ item.updatedAt }}</td>
                  <td style="width: 13%">
                    <base-button
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                      @click="editUser(item.uuid)"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <!--<base-button
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                      @click="modals.delete = true"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>-->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
    <modal
      :show.sync="modals.delete"
      id="deleteModal"
      :centered="false"
      :show-close="true"
    >
      <template slot="header">
        <h5 class="modal-title">Eliminar Usuario</h5>
      </template>
      <div>¿Está seguro de eliminar el usuario?</div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.delete = false"
          >Cancelar</base-button
        >
        <base-button type="primary">Eliminar</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import "datatables.net-responsive-dt";
import "datatables.net-fixedheader-bs5";
import Modal from "@/components/Modal";

//import "datatables.net-fixedheader-bs5";
//https://datatables.net/download/npm
//https://datatables.net/extensions/fixedheader/examples/integration/responsive-bootstrap.html

export default {
  components: {
    BaseTable,
    DataTable,
    Modal,
  },

  data() {
    return {
      sheets: [],
      usuario: [],
      loadingvar: false,
      table: null,
      modals: {
        delete: false,
      },
    };
  },
  watch: {
    sheets() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = new DataTable("#myTable", {
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
  },
  async mounted() {
    this.table = new DataTable("#myTable", {
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getList();
  },
  methods: {
    async getList() {
      await this.$store.dispatch("sheets/list");
      this.sheets = await this.$store.getters["sheets/list"];
    },
    async editUser(id) {
      let route = this.$router.resolve({
        path: "/laminas/lamina-edit?id=" + id,
      });
      window.open(route.href);
    },
    async showImage(tira) {
      const response = await fetch(tira, {
        method: "GET",
      });

      if (response.status === 200) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        return window.open(imageObjectURL);
      } else {
        return "";
      }
    },
  },
};
</script>
