<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Lista de Roles</h4>
          <div class="text-right mb-3">
            <button class="btn btn-primary" @click="modals.edit = true">
              Añadir
            </button>
          </div>
          <div>
            <table
              id="myTable"
              class="table table-responsive"
              style="width: 100%"
            >
              <thead>
                <tr>
                  <th style="width: 100%">Nombres</th>
                  <th style="width: 10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in roles" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>
                    <!--<base-button
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                      @click="modals.edit = true"
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <base-button
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                      @click="deleteRole(item.id)"
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>-->
                  </td>
                </tr>
              </tbody>
            </table>
            <modal
              :show.sync="modals.edit"
              :centered="false"
              :show-close="true"
            >
              <template slot="header">
                <h5 class="modal-title" id="exampleModalLabel">Añadir rol</h5>
              </template>

              <base-input
                class="col-md-12"
                type="text"
                placeholder="Rol"
                v-model="form.data.name"
              />

              <template slot="footer">
                <base-button type="secondary" @click="modals.edit = false"
                  >Cerrar</base-button
                >
                <base-button type="primary" @click="addRole"
                  >Guardar</base-button
                >
              </template>
            </modal>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";
import Modal from "@/components/Modal";

//import "datatables.net-fixedheader-bs5";
import $ from "jquery";
const tableColumns = ["Name", "Email", "Created At"];
//https://datatables.net/download/npm
//https://datatables.net/extensions/fixedheader/examples/integration/responsive-bootstrap.html

export default {
  components: {
    BaseTable,
    DataTable,
    Modal,
  },

  data() {
    return {
      roles: [],
      loadingvar: false,
      table: null,
      modals: {
        edit: false,
      },
      form: {
        data: {
          name: "",
        },
      },
    };
  },
  watch: {
    roles() {
      this.table.destroy();
      // data used in the table
      this.$nextTick(() => {
        // wait until data fully updated before re-render new DOM
        this.table = new DataTable("#myTable", {
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
  },
  async mounted() {
    this.table = new DataTable("#myTable", {
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getList();
  },
  methods: {
    async getList() {
      await this.$store.dispatch("roles/list");
      this.roles = await this.$store.getters["roles/list"];
    },
    async addRole() {
      try {
        await this.$store.dispatch("roles/add", this.form.data);
        this.$notify({
          type: "success",
          message: "El rol fue creado.",
        });
        this.getList();
        this.modals.edit = false;
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
    deleteRole(id) {
      console.log(id);
    },
    editRole(id) {
      console.log(id);
    },
  },
};
</script>
