<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Crear Permiso</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row">
              <base-input
                class="col-md-3"
                type="text"
                label="Nombre"
                placeholder="Nombre"
                v-model="form.data.nombre"
              />
              <base-input class="col-md-3" label="Rol">
                <select v-model="form.data.entorno" class="form-control">
                  <option value="web">web</option>
                  <option value="editor">editor</option>
                </select>
              </base-input>
            </div>
            <base-button type="primary" native-type="Submit"
              >Guardar</base-button
            >
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      roles: [],
      form: {
        data: {
          nombre: "",
          entorno: "web",
        },
      },
    };
  },

  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch("permissions/add", this.form.data);
        this.$notify({
          type: "success",
          message: "El permiso fue creado.",
        });
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
  },
};
</script>
