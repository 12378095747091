<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Crear Cliente</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row">
              <base-input
                class="col-md-3"
                type="text"
                label="Nombres"
                placeholder="Nombres"
                v-model="form.data.first_name"
              />
              <base-input
                class="col-md-3"
                type="text"
                label="Apellidos"
                placeholder="Apellidos"
                v-model="form.data.last_name"
              />
              <base-input
                class="col-md-3"
                type="number"
                label="Celular"
                placeholder="Celular"
                v-model="form.data.contact_number"
              />
              <base-input class="col-md-3" label="Rol">
                <select
                  id="inputRole"
                  class="form-control"
                  v-model="form.data.rol"
                >
                  <option v-for="item in roles" :value="item.id" :key="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </base-input>
            </div>
            <div class="form-row">
              <base-input
                class="col-md-6"
                type="email"
                label="Email"
                placeholder="Email"
                v-model="form.data.email"
              />
              <base-input
                class="col-md-6"
                type="password"
                label="Password"
                placeholder="Password"
                v-model="form.data.password"
              />
            </div>
            <base-button type="primary" native-type="Submit"
              >Guardar</base-button
            >
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      roles: [],
      form: {
        data: {
          first_name: "",
          last_name: "",
          contact_number: "",
          rol: 3,
          email: "",
          password: "",
        },
      },
    };
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch("users/add", this.form.data);
        this.$notify({
          type: "success",
          message: "El usuario fue creado.",
        });
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },

    async getList() {
      await this.$store.dispatch("roles/filter", {
        attribute: "name",
        value: "user",
      });
      this.roles = await this.$store.getters["roles/list"];
    },
  },
};
</script>
