<template>
  <div>
    <div class="container">
      <div class="col-lg-4 col-md-6 mt-3 ml-auto mr-auto">
        <form @submit.prevent="handleSubmit()">
          <card class="card-login card-white">
            <div class="card-header mt-2">
              <img src="/img/logo_elamina.svg" alt="" />
            </div>

            <div>
              <base-input
                required
                v-model="email"
                type="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-email-85"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.email" />

              <base-input
                required
                v-model="password"
                placeholder="Password"
                addon-left-icon="tim-icons icon-lock-circle"
                type="password"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.password" />
            </div>

            <div slot="footer">
              <base-button
                native-type="submit"
                type="primary"
                class="mb-3"
                size="lg"
                block
              >
                Acceder
              </base-button>

              <div class="pull-right">
                <h6>
                  <a
                    href="/password/reset"
                    class="link footer-link"
                    style="font-weight: 500; color: #1e1e2f"
                    >¿Olvidaste la contraseña?</a
                  >
                </h6>
              </div>
            </div>
          </card>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  mixins: [formMixin],
  components: {
    ValidationError,
  },
  data() {
    return {
      email: "admin@jsonapi.com",
      password: "secret",
      subscribe: true,
    };
  },
  methods: {
    async handleSubmit() {
      const user = {
        email: this.email,
        password: this.password,
        enviroment: "admin",
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        await this.$store.dispatch("login", { user, requestOptions });
      } catch (e) {
        this.$notify({
          message: "Invalid credentials!",
          icon: "tim-icons icon-bell-55",
          type: "danger",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
