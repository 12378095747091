<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">Asignar Funciones a un Plan</h4>
          <form @submit.prevent="handleSubmit()">
            <div class="form-row">
              <base-input class="col-md-4" label="Funciones">
                <select
                  id="inputUser"
                  class="form-control"
                  v-model="form.data.function_id"
                >
                  <option
                    v-for="item in functionalities"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.description }} - Habilitado: {{ item.enabled }}
                    - Ilimitado:
                    {{ item.unlimited }}
                    <span v-if="item.amount !== null">
                      - Cantidad: {{ item.amount }}
                    </span>
                    <span v-if="item.formats !== null">
                      - Formatos: {{ item.formats }}
                    </span>
                    <span v-if="item.fonts !== null">
                      - Fuentes: {{ item.fonts }}
                    </span>
                  </option>
                </select>
              </base-input>
              <base-input class="col-md-4" label="Plan">
                <select
                  id="inputPlan"
                  class="form-control"
                  v-model="form.data.plan_id"
                >
                  <option v-for="item in plans" :value="item.id" :key="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </base-input>
              <div class="from-group col-md-4 mt-3" style="text-align: center">
                <base-button type="primary" native-type="Submit"
                  >Guardar</base-button
                >
              </div>
            </div>
          </form>
        </card>
      </div>
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">
            Lista de Funciones y Planes asignados
          </h4>

          <table
            id="myTable"
            class="table table-responsive"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Estado</th>
                <th>¿Por defecto?</th>
                <th style="width: 50%">Funcionalidad</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in planFunctionalities" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.isDefault }}</td>
                <td>
                  <li
                    v-for="functionality in item.functionalities"
                    :key="functionality.id"
                  >
                    {{ functionality.description }}
                    <ul style="margin-bottom: 0">
                      <b>Habilitado:</b>
                      {{
                        functionality.enabled
                      }}
                    </ul>

                    <ul
                      v-if="functionality.amount !== null"
                      style="margin-bottom: 0"
                    >
                      <b>Cantidad:</b>
                      {{
                        functionality.amount
                      }}
                    </ul>
                    <ul
                      v-if="functionality.formats !== null"
                      style="margin-bottom: 0"
                    >
                      <b>Formatos:</b>
                      {{
                        functionality.formats
                      }}
                    </ul>
                    <ul
                      v-if="functionality.fonts !== null"
                      style="margin-bottom: 0"
                    >
                      <b>Fuentes:</b>
                      {{
                        functionality.fonts
                      }}
                    </ul>
                    <ul style="margin-bottom: 0">
                      <b>Ilimitado:</b>
                      {{
                        functionality.unlimited
                      }}
                    </ul>
                  </li>
                </td>
                <td>
                  <base-button
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                    @click="modals.edit = true"
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                  <base-button
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                    @click="deletePlan(item.id)"
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </td>
              </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import DataTable from "datatables.net-bs5";
import "datatables.net-responsive-bs5";

export default {
  components: { BaseTable, DataTable },

  data() {
    return {
      table: null,
      plans: [],
      users: [],
      planFunctionalities: [],
      functionalities: [],
      form: {
        data: {
          function_id: 1,
          plan_id: 1,
        },
      },
    };
  },
  watch: {
    planFunctionalities() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = new DataTable("#myTable", {
          columnDefs: [
            {
              defaultContent: "",
              targets: "_all",
            },
          ],
        });
      });
    },
  },
  async mounted() {
    this.table = new DataTable("#myTable", {
      columnDefs: [
        {
          defaultContent: "",
          targets: "_all",
        },
      ],
    });
    await this.getPlanFunctionalities();
    await this.getPlans();
    await this.getUsers();
    await this.getFunctionalities();
  },
  methods: {
    async getPlanFunctionalities() {
      await this.$store.dispatch("plans/include", {
        value: "functionalities",
      });
      this.planFunctionalities = await this.$store.getters["plans/list"];
    },
    async getPlans() {
      await this.$store.dispatch("plans/list");
      this.plans = await this.$store.getters["plans/list"];
    },
    async getUsers() {
      await this.$store.dispatch("users/list");
      this.users = await this.$store.getters["users/list"];
    },
    async getFunctionalities() {
      await this.$store.dispatch("functionalities/list");
      this.functionalities = await this.$store.getters["functionalities/list"];
    },
    async handleSubmit() {
      try {
        await this.$store.dispatch("plan_users/add", this.form.data);
        this.$notify({
          type: "success",
          message: "El plan fue asignado.",
        });
      } catch (error) {
        let errors = error.response.data.errors;

        const arrayFailed = Object.entries(errors).map((arr) => ({
          message: arr[1],
        }));

        arrayFailed.forEach((element) => {
          element["message"].forEach((responseError) => {
            this.$notify({
              type: "danger",
              message: responseError,
            });
          });
        });
      }
    },
  },
};
</script>
